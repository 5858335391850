import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "../styles/global.css"
import svgFix from "../../static/server-down.svg"

export default function Layout({children}) {
  return (
    <div>
      {/*<Navbar/>*/}
      {/*{ children }*/}
      {/*<Footer />*/}
      <div className={"under-maintenance flex-center-cl"}>
        <img src={svgFix} alt="" />
        <h1>We are Under Maintenance...</h1>
        <h4>We are improving our website and coming back to you as soon as possible</h4>
      </div>
    </div>
  )
}